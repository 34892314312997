<template>
  <Div>

    <h1>{{ msg }}</h1>
    <p style="color:gray;">仅限满足条件者</p>
    <el-input v-model="phone" placeholder="请输入手机号" type="tel" style="font-size:17px; width:150px; height:45px;" input-style="text-align:center;"/>
    <p style="color:red" v-show="phone.length>11 || (phone.length==11 && objectId.length==0 && loaded==true)"><small>无匹配资料</small></p>
    <p></p>
    <el-input v-model="fullname" v-show="objectId.length>0 && phone.length==11" placeholder="姓名" style="font-size:17px; width:100px; height:45px;" input-style="text-align:center;"></el-input>
    <p></p>
    <el-input type="textarea" rows="2" v-model="address" v-show="objectId.length>0 && phone.length==11" placeholder="收货地址" style="font-size:17px; width:240px;" input-style="text-align:center;"></el-input>
    <p></p>
    <el-button type="primary" @click="dialogVisible = true" v-show="objectId.length>0 && phone.length==11" style="font-size:19px; width:116px; height:44px;">确认地址</el-button>


    <el-dialog
      v-model="dialogVisible"
      title="确认地址"
      width="270px"
    >
      <center>
        <p style="font-size:15px; text-align:left; width:190px; color:black"><strong>电话：{{phone}}</strong></p>
        <p style="font-size:15px; text-align:left; width:190px; color:black"><strong>姓名：{{fullname}}</strong></p>
        <p style="font-size:15px; text-align:left; width:190px; color:black"><strong>地址：{{address}}</strong></p>
      </center>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false" style="font-size:17px; height:40px;">返回</el-button>
          <el-button type="primary" @click="updateUser()" style="font-size:17px; height:40px;">提交</el-button>
        </span>
      </template>
    </el-dialog>

  </Div>
</template>

<script>

const AV = require('leancloud-storage');
AV.init({
    appId: "bfcb37pfjnk42rrchdx2dt3mkwzu0hglj7gsf6txpyjlvzw1",
    appKey: "ymiwlu1qjrbpy9d9z1eyg8by4zvhykcu9pspqq5zix1792pd",
    serverURL: "https://bfcb37pf.lc-cn-n1-shared.com"
});

const axios = require('axios').default;
const axios_av = axios.create({
  baseURL: 'https://bfcb37pf.lc-cn-n1-shared.com',
  headers: {'X-LC-Id': 'bfcb37pfjnk42rrchdx2dt3mkwzu0hglj7gsf6txpyjlvzw1',
            'X-LC-Key': 'j10gmieo9z891otcqr9dwvn52y3wizchq9hxawp9srakblb1,master'}
});

import { ElMessage } from 'element-plus'

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      phone: '',
      fullname: '',
      address: '',
      objectId: '',
      loaded: false,
      dialogVisible: false,
    }
  },
  watch: {
      phone(newPhone, oldPhone) {
        console.log(oldPhone)
        this.loaded = false
        if (newPhone.length == 11) {
          this.fetchUser()
        } else {
          this.fullname = ''
          this.address = ''
          this.objectId = ''
        }
      }
    },
  methods: {
    fetchUser() {
      const query = new AV.Query('_User').equalTo('username', this.phone)
      query.first()
        .then((obj) => {
          this.fullname = obj.get('fullname')
          this.address = obj.get('address')
          if (this.address == undefined || this.address.trim().length == 0) {
            this.address = obj.get('addressOriginal')
          }
          this.objectId = obj.get('objectId')
          this.loaded = true
        })
        .catch(() => {
          this.loaded = true
        })
    },
    updateUser() {
      axios_av
      .put(`https://bfcb37pf.lc-cn-n1-shared.com/1.1/users/${this.objectId}`,
          { 'fullname': this.fullname, 
            'address': this.address, 
            'giftConfirmed': true }
      )
      .then(() => {
        ElMessage({
          message: '提交成功，我们会尽快安排发货。',
          type: 'success',
          duration: 6000
        })
        this.dialogVisible = false
        this.phone = ''
        this.fullname = ''
        this.address = ''
        this.objectId = ''
      })
      .catch(function (error) {
        alert(error)
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>